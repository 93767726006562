@import '../../style/App.scss';

.team__info {
  max-width: 370px;
  height: 420px;
  margin: 0 auto;
  margin-top: 60px;
  position: relative;
  border: 4px solid $light-color;
  padding: 50px;
  margin-bottom: 60px;

  h4 {
    padding-bottom: 20px;
  }
  p {
    padding-bottom: 20px;
  }
  p:last-child {
    cursor: pointer;
    transition: 0.3s ease;
  }
  p:last-child:hover {
    color: $main-color;
  }
}